<template>
    <div class="row">
        <div class="col-12 mb-2">
            <h4>{{ $t('profile.personal_data.verify_email_address.headline') }}</h4>
        </div>
        <div class="col-12">
            <MoleculeLoader size="md" v-if="actionIsRunning" />
            <MoleculeForm @submit.prevent="submit" class="d-flex align-items-end" v-else>
                <AtomInput :animated-label="false" :disabled="actionIsRunning" :label="$t('profile.personal_data.verify_email_address.confirmation_code')" v-model="state.token" :error="v$.token.$error">
                    <template #error-message>{{ $t(v$.token.$errors[0].$message) }}</template>
                </AtomInput>
                <AtomButton class="ms-3" type="submit" @click.prevent="submit" :disabled="actionIsRunning">
                    {{ $t('helper.actions.verify') }}
                </AtomButton>
                <AtomButton class="ms-3" @click.prevent="requestCode" variant="secondary" :disabled="actionIsRunning" v-if="!user?.emailVerified">
                    {{ $t('helper.actions.request_new') }}
                </AtomButton>
            </MoleculeForm>
        </div>
        <div class="col-12 mt-4" v-if="!user?.emailVerified">
            <AtomAlert variant="warning" show :dismissible="false">
                <p v-html="$t('profile.personal_data.verify_email_address.warning')"></p>
            </AtomAlert>
        </div>
    </div>
</template>
<script setup>
    import {reactive, ref} from "vue";
    import {useVuelidate} from "@vuelidate/core";
    import {required} from '@development/cluster-center/src/utils/validators';
    import {resetValidation, validate} from '@development/cluster-center/src/composables/validation';
    import {ResponseService, ErrorHandler, userStore} from '@development/cluster-center';

    const actionIsRunning = ref(false);
    const state = reactive({token: ''});
    const rules = {token: { required }};
    const $externalResults = ref({});
    const v$ = useVuelidate(rules, state, { $externalResults });
    const i18n = window.Config.get('i18n');
    const support = window.Config.get('support');
    const user = userStore();

    const submit = async () => {
        await resetValidation(v$.value);

        if (!await validate(v$.value)) {
            return;
        }

        actionIsRunning.value = true;

        await user.verifyEmailAddress(state).then(response => {
            actionIsRunning.value = false;

            if (Array.isArray(response)) {
                if (response[2] === 401) {
                    return;
                }

                if (response[2] === 429) {
                    return ResponseService.danger(i18n.t('errors.too_many_attempts', {phone: support.phone_formatted, email: support.email}));
                }

                if (response[2] === 422) {
                    $externalResults.value = ErrorHandler.parseEngineErrors(response[0].response.data.errors);
                    return;
                }

                return ResponseService.danger(i18n.t(response[1], {phone: support.phone_formatted, email: support.email }));
            }

            ResponseService.success(i18n.t('profile.personal_data.verify_email_address.success'));
        });
    };

    const requestCode = async () => {
        await user.requestEmailVerification().then(response => {
            if (Array.isArray(response)) {
                if (response[2] === 401) {
                    return;
                }

                if (response[2] === 429) {
                    return ResponseService.danger(i18n.t('errors.too_many_attempts', {phone: support.phone_formatted, email: support.email}));
                }

                return ResponseService.danger(i18n.t(response[1], {phone: support.phone_formatted, email: support.email }));
            }

            ResponseService.success(i18n.t('profile.personal_data.verify_email_address.send_verification_code'));
        });
    };
</script>